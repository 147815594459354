<template>
  <div class="mt-5">
    <h2 class="ml-3 mb-5 text-left">{{ $t("factory.standard") }}</h2>
    <!-- Fix inline width? -->
    <b-container>
      <b-row>
        <b-col><img :src="images.hacpp" alt="HACPP" width="140px" /></b-col>
        <b-col><img :src="images.gmp" alt="GMP" width="105px" /></b-col>
        <b-col
          ><img :src="images.halal" alt="Halal" class="mt-3" width="110px"
        /></b-col>
      </b-row>
    </b-container>

    <img :src="images.factory" alt="factory" width="100%" />
  </div>
</template>

<script>
import { storage } from "../../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  data() {
    return {
      images: {
        hacpp: "",
        gmp: "",
        halal: "",
        factory: "",
      },
    };
  },
  created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `factory/standard/${key}.png`)).then(
        (url) => {
          this.images[key] = url;
        }
      )
    );
  },
};
</script>

<style scoped>
.row {
  padding: 10px 20% 10px 20%;
}
h2 {
  padding-left: 15px;
  font-size: 35px;
}
.container {
  margin: auto;
}
@media screen and (max-width: 1000px) {
  .row {
    padding: 0 0 0 0;
    margin: auto;
  }
}
@media screen and (max-width: 480px) {
  .container img {
    width: 100px;
    position: relative;
    padding: 5px;
  }
}
</style>
