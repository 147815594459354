<template>
  <div>
    <Banner />
    <div class="grid">
      <img :src="gridImg" alt="" />
    </div>
    <Standard />
  </div>
</template>

<script>
import Banner from "@/components/OEM/Banner.vue";
import Standard from "@/components/factory/Standard.vue";
import { storage } from "../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  components: { Banner, Standard },
  data() {
    return {
      gridImg: "",
    };
  },

  created() {
    getDownloadURL(ref(storage, "factory/factory_head.png")).then((url) => {
      this.gridImg = url;
    });
  },
};
</script>

<style scoped>
.grid {
  background-color: #2a3e47;
}
img {
  width: 65%;
}

@media screen and (max-width: 1000px) {
  img {
    width: 100%;
  }
}
</style>
